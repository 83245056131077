

























































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import submission from "@/store/modules/submission";

@Component
export default class Successful extends Vue {
  submissionModule!: submission;
  signedUpAndroid = false;
  playStoreLink = "";
  signedUpIos = false;

  created() {
    this.submissionModule = getModule(submission, this.$store);
  }
  mounted() {
    this.signedUpAndroid = this.submissionModule.signedUpAndroid ?? false;
    this.playStoreLink = process.env.VUE_APP_ANDROID_JOIN_LINK;
    this.signedUpIos = this.submissionModule.signedUpIos ?? false;
  }
}
